import { isValidElement, memo, useMemo } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { separateRichTextNode } from '../../../helpers/common';
import { useInfoTabContext } from '../../../context/infotab-context';

const SpecsAndDetail: React.FC = () => {
    const { product } = useInfoTabContext();

    const specDetails: any = useMemo(() => {
        const seperatedSpecDetail =  separateRichTextNode(product?.[0]?.fields?.specsDetails?.content || []);
        return seperatedSpecDetail.map(element => ({
            nodeType: BLOCKS.DOCUMENT,
            content: element,
            data: {}
        }));
      }, [product]);

      const option = useMemo(() => {
        return {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
                  return <img src={`${node?.data?.target?.fields?.file?.url}`} alt={`${node?.data?.target?.fields?.title}`} className='rounded-2xl w-full md:max-w-[500px] mx-auto' />
                },
                [BLOCKS.HEADING_3]: (node: any, children: any) => <div className='font-inter text-20 mb-5 pl-2 lg:pl-16 capitalize'>{children}</div>,
                [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
                  const videoSrc = children?.[0];
                  if (isValidElement(videoSrc)) {
                    return <div>{children}</div>;
                  }

                  return children?.[0]?.includes('youtu') ? 
                    <div className='youtube-embeded'><iframe className='w-full h-full min-h-[400px] rounded-2xl' src={videoSrc} title='YouTube video player' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerPolicy='strict-origin-when-cross-origin' allowFullScreen></iframe></div>
                    : <div>{children}</div>;
                },
                [BLOCKS.UL_LIST]: (node: any, children: any) =>  <ul className='flex flex-col gap-4 list-disc pl-5 py-5 contentful-list'>{children}</ul>,
                [BLOCKS.HR]: (node: any, children: any) => <div className='mt-4'></div>,
                [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='px-3'>{children}</li>,
                [BLOCKS.TABLE]: (node: any, children: any) => <div className='border border-solid rounded-2xl my-4'><table className='table-fixed text-center rounded-2xl p-6 border border-solid overflow-hidden w-full font-sans'>{children}</table></div>,
                [BLOCKS.TABLE_HEADER_CELL]: (node: any, children: any) => <th className=' font-semibold text-16 p-2 border bg-gray-300'>{children}</th>,
                [BLOCKS.TABLE_ROW]: (node: any, children: any) => <tr className='border'>{children}</tr>,
                [BLOCKS.TABLE_CELL]: (node: any, children: any) => <td className='border'>{children}</td>,
            }
        }
    }, []);

    return (
        <div className='flex flex-col xl:max-w-container md:space-y-10 mb-10 md:mb-0 md:mt-14 mx-auto'>
        {
          specDetails?.map((element: any, index: any) => (
            (<div key={`key_${index}`} className={`flex flex-col ${index % 2 === 1 ? 'lg:flex-row' : 'lg:flex-row-reverse kf-reverse'}`}>
               <div className='md:flex-1 key-features-img rounded-2xl'>
                {
                  documentToReactComponents({...element, content: element?.content?.[0] ? [element?.content?.[0]] : []}, option)
                }
              </div>
              <div className='flex flex-col xs:p-16px py-16px md:flex-1 kf-title justify-center'>
                {documentToReactComponents({...element, content: element?.content?.[1] ? [element?.content[1]] : []}, option)}
                <pre className='pre-wrap-custom lg:pl-16 kf-description'>
                  {documentToReactComponents({...element, content: [...element?.content?.slice(2)]}, option)}
                </pre>
              </div>
            </div>)
          ))
        }
      </div>
    );
};

export default memo(SpecsAndDetail);