

import React, { memo } from 'react';

const PlusIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="3" viewBox="0 0 24 3" className='fill-white stroke-white block my-auto'>
    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -16.5)">
      <path id="Path_208" data-name="Path 208" d="M7.5,18h21" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
  </svg>
);

export default memo(PlusIcon);
