import React, { memo, useEffect, useMemo } from 'react';
import FAQ from '../components/product-details/tabs/faq-tab';
import Breadcrumb from '../components/breadcrumb';
import { BLOCKS } from '@contentful/rich-text-types';
import './products-page.css';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import { separateRichTextNode } from '../helpers/common';
  
const FAQpage: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const banner = useMemo(() => filterItems(rawContentFul, 'banner')?.filter(data => (
    data?.fields?.location === 'faq'
  ))?.[0], [rawContentFul]);

  const faqContent = useMemo(() => filterItems(rawContentFul, 'faq')?.filter(data => !data?.fields?.isOnHomepage)?.[0], [rawContentFul]);
  const faq: any = useMemo(() => {
    //@ts-ignore
    const seperatedFaq =  separateRichTextNode(faqContent?.fields?.faq?.content || []);
    //@ts-ignore
    return seperatedFaq.map(element => ({
        nodeType: BLOCKS.DOCUMENT,
        content: element,
        data: {}
    }));
  }, [faqContent]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className='w-full h-full'>
      <div className='flex flex-col max-w-container w-full'>
        <div className='p-4 w-full text-left lg:hidden'>
          <Breadcrumb data={[
              {
              text: 'FAQ',
              link: '/faq'
              }
          ]} />
        </div>
      <div className='product-banner h-64 relative bg-[#F4F4F8] rounded-2xl mx-4 xl:mx-0'>
        <div className='text-left md:max-w-2xl absolute mt-5 ml-5 lg:mt-10 lg:ml-10 text-black flex flex-col gap-3'>
          <div className='font-inter text-22 md:text-28 md:leading-10'>{banner?.fields?.headline || <>Looking for a help? <br/> Here are our most frequently asked questions</>}</div>
          <div className='font-sans text-14 md:text-18 font-normal'>
            {banner?.fields?.subHeadline && 'Everything you need to know about Align Array. Can’t find the answer to a question you have? No worries, just click “Chat to our team”!'}
          </div>
        </div>
        <div className='w-full h-full flex justify-end'>
          {/* @ts-ignore */}
          <img className='h-fit sm:h-full object-cover rounded-2xl' src={`${ banner?.fields?.photo?.fields?.file?.url || '/static/media/questionBanner.466e3f19e3631c1db285.png'}`} alt="Troubleshooting"></img>
        </div>
      </div>
      </div>
      <div className='p-4 pb-16 xl:max-w-container lg:px-0 mx-auto'>
        {/* @ts-ignore */}
        <FAQ faqProps={faq} isCompact={false} />
      </div>
    </div>
    
  )
};

export default memo(FAQpage);
