import React, { memo, useMemo } from 'react';
import _sortBy from 'lodash/sortBy';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import { Link } from 'react-router-dom';

const HomePageDesktopProductCategory: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const categories = useMemo(() => {
    return _sortBy(filterItems(rawContentFul, 'productCategory'), 'fields.order')?.filter((el, index) => index < 4);
  }, [rawContentFul]);

  return (
    <div className='lg:flex items-center gap-[30px] mt-7 hidden'>
      {
        categories?.slice(1).map(item => (
          <div className='flex-1 aspect-square flex flex-col relative' key={item.sys.id}>
            
            <div className='absolute right-0 bottom-0 w-full h-full bg-cover rounded-2xl' style={{ backgroundImage: `url(${
              // @ts-ignore
              item.fields?.desktopPhoto?.fields?.file?.url
              })` }}>
              
            </div>
            <div className='flex flex-col px-8 py-11 z-10'>
            <div className='text-[32px] text-black uppercase font-inter font-semibold'>
              {item.fields.name}
            </div>
            <Link to={`/products?category=${encodeURIComponent(item?.fields?.name?.toString().toLowerCase() || '')}`}>
            <div className='flex items-center text-4 font-poppins px-6 py-2 justify-center space-x-3 bg-white rounded-[60px] text-black leading-[30px] mt-4 w-fit hover:bg-white/[0.3] transition-all'>
              <div>
                <Link to={`/products?category=${encodeURIComponent(item?.fields?.name?.toString().toLowerCase() || '')}`}>Show More</Link>
              </div>
                <svg className='hover:fill-black' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='white' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
              </div>
            </Link>
            </div>
          </div>
        ))
      }
    </div>
  )
};

export default memo(HomePageDesktopProductCategory);
