import React, { memo } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const FooterSocialLink: React.FC<{ children: JSX.Element, url: string }> = ({ children, url }) => _isEmpty(url) === true ? <></> : (
  <a href={url} target='_blank' rel='noreferrer'>
    <span className='hidden'>Social Media</span>
    {children}
  </a>
);

interface IProps {
  links: string[];
  inverted?: boolean;
  isVideoTop?: boolean;
  color?: string;
  width?: string;
  height?: string
};

const FooterIcon: React.FC<IProps> = ({ links, inverted, isVideoTop, color, width, height }) => (
  <>
    <FooterSocialLink url={_get(links, 0)}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '30px'} height={height || '30px'} viewBox="0 0 30 30">
      <path id="Icon_simple-facebook" data-name="Icon simple-facebook" d="M30,15.092A15,15,0,1,0,12.656,30V19.454H8.848V15.092h3.809V11.767c0-3.782,2.239-5.872,5.666-5.872a22.933,22.933,0,0,1,3.358.295V9.9H19.788a2.175,2.175,0,0,0-2.445,2.357v2.831H21.5l-.665,4.362h-3.5V30A15.067,15.067,0,0,0,30,15.092Z" fill={color || '#7b7b7b'}/>
    </svg>
    </FooterSocialLink>
    <FooterSocialLink url={_get(links, 1)}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '30'} height={height || '30'} viewBox="0 0 30 30">
      <path id="twitter" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm6.849,11.7q.01.221.01.445a9.729,9.729,0,0,1-9.8,9.8h0A9.745,9.745,0,0,1,6.785,20.39a7,7,0,0,0,.821.048,6.908,6.908,0,0,0,4.276-1.474,3.447,3.447,0,0,1-3.216-2.391,3.431,3.431,0,0,0,1.555-.059,3.444,3.444,0,0,1-2.762-3.375c0-.016,0-.03,0-.044a3.419,3.419,0,0,0,1.559.431,3.446,3.446,0,0,1-1.066-4.6,9.775,9.775,0,0,0,7.1,3.6,3.445,3.445,0,0,1,5.867-3.14A6.906,6.906,0,0,0,23.1,8.55a3.456,3.456,0,0,1-1.514,1.9,6.867,6.867,0,0,0,1.977-.542A7,7,0,0,1,21.849,11.7Zm0,0" fill={color || '#7b7b7b'}/>
    </svg>
    </FooterSocialLink>
    <FooterSocialLink url={_get(links, 2)}>
    <svg id="instagram_1_" data-name="instagram (1)" xmlns="http://www.w3.org/2000/svg" width={width || '30'} height={height || '30'} viewBox="0 0 30 30">
      <path id="Path_143" data-name="Path 143" d="M212.57,209.785A2.785,2.785,0,1,1,209.785,207,2.785,2.785,0,0,1,212.57,209.785Zm0,0" transform="translate(-194.785 -194.785)" fill={color || '#7b7b7b'}/>
      <path id="Path_144" data-name="Path 144" d="M148.848,137.41a2.767,2.767,0,0,0-1.586-1.586,4.622,4.622,0,0,0-1.551-.288c-.881-.04-1.145-.049-3.376-.049s-2.5.008-3.376.049a4.625,4.625,0,0,0-1.551.288,2.768,2.768,0,0,0-1.586,1.586,4.624,4.624,0,0,0-.288,1.552c-.04.881-.049,1.145-.049,3.376s.009,2.5.049,3.376a4.622,4.622,0,0,0,.288,1.551,2.767,2.767,0,0,0,1.586,1.586,4.617,4.617,0,0,0,1.552.288c.881.04,1.145.049,3.376.049s2.5-.008,3.376-.049a4.617,4.617,0,0,0,1.552-.288,2.767,2.767,0,0,0,1.586-1.586,4.627,4.627,0,0,0,.288-1.551c.04-.881.049-1.145.049-3.376s-.008-2.5-.049-3.376A4.617,4.617,0,0,0,148.848,137.41Zm-6.513,9.218a4.29,4.29,0,1,1,4.29-4.29A4.29,4.29,0,0,1,142.334,146.628Zm4.46-7.748a1,1,0,1,1,1-1A1,1,0,0,1,146.794,138.881Zm0,0" transform="translate(-127.334 -127.338)" fill={color || '#7b7b7b'}/>
      <path id="Path_145" data-name="Path 145" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm8.561,18.551a6.319,6.319,0,0,1-.4,2.091,4.4,4.4,0,0,1-2.519,2.519,6.324,6.324,0,0,1-2.091.4c-.919.042-1.212.052-3.551.052s-2.633-.01-3.551-.052a6.324,6.324,0,0,1-2.091-.4,4.4,4.4,0,0,1-2.519-2.519,6.318,6.318,0,0,1-.4-2.091C6.4,17.633,6.387,17.339,6.387,15s.01-2.633.052-3.551a6.32,6.32,0,0,1,.4-2.091A4.407,4.407,0,0,1,9.358,6.839a6.325,6.325,0,0,1,2.091-.4c.919-.042,1.212-.052,3.551-.052s2.633.01,3.551.052a6.327,6.327,0,0,1,2.091.4,4.405,4.405,0,0,1,2.519,2.519,6.319,6.319,0,0,1,.4,2.091c.042.919.052,1.212.052,3.551S23.6,17.633,23.561,18.551Zm0,0" fill={color || '#7b7b7b'}/>
    </svg>
    </FooterSocialLink>
    <FooterSocialLink url={_get(links, 3)}>
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width={width || '30'} height={height || '30'} viewBox="0 0 30 30">
      <g id="Color">
        <g id="_02.YouTube" data-name="02.YouTube">
          <path id="Path_146" data-name="Path 146" d="M65.46,69.485,70.4,66.62,65.46,63.74Z" transform="translate(-51.93 -51.613)" fill={color || '#7b7b7b'}/>
          <path id="Path_147" data-name="Path 147" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm7.895,17.655a2.986,2.986,0,0,1-2.986,2.988H10.091a2.986,2.986,0,0,1-2.986-2.988V12.345a2.986,2.986,0,0,1,2.986-2.988h9.817a2.986,2.986,0,0,1,2.986,2.988Z" fill={color || '#7b7b7b'}/>
        </g>
      </g>
    </svg>
    </FooterSocialLink>
  </>
);

export default memo(FooterIcon);
