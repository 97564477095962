import React, { memo, useMemo } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import ToolLogo from '../../assets/questionBanner.png';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import ChatIcon from '../../assets/chatIcon.svg';
import FAQ from '../product-details/tabs/faq-tab';
import { separateRichTextNode } from '../../helpers/common';

const IntroductionHomePage: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const banner = useMemo(() => filterItems(rawContentFul, 'banner')?.filter(data => (
    data?.fields?.location === 'home'
  ))?.[0], [rawContentFul]);

  const faqContent = useMemo(() => filterItems(rawContentFul, 'faq')?.filter(data => data?.fields?.isOnHomepage)?.[0], [rawContentFul]);
  const faq: any = useMemo(() => {
    //@ts-ignore
    const seperatedFaq =  separateRichTextNode(faqContent?.fields?.faq?.content || []);
    //@ts-ignore
    return seperatedFaq.map(element => ({
        nodeType: BLOCKS.DOCUMENT,
        content: element,
        data: {}
    }));
  }, [faqContent]);

  return (
    <div className='flex flex-col px-4 lg:px-0 space-y-7 mt-8 lg:mt-7 lg:flex-row lg:space-y-0 lg:space-x-6'>
      <div className='relative lg:flex-1'>
        {/* @ts-ignore */}
        <img className='w-fit h-[400px] xxxs:h-[300px] xs:h-full object-cover rounded-2xl' src={`${null || ToolLogo}`} alt='Troubleshooting' />
        <div className='w-full absolute top-0 text-left flex flex-col mt-5 lg:mt-6 items-start px-4 lg:px-0'>
          <div className='text-[22px] font-inter lg:text-[32px] lg:pl-11 lg:pr-9 w-4/5'>{`${ banner?.fields?.headline || 'Troubleshooting is stressfull enough'}`}</div>
          <div className='text-[15px] mt-3 lg:mt-6 lg:px-8 lg:pl-11 lg:pr-9 lg:text-[16px] backdrop-blur-sm text-[#4A4A4A]'>{`${
            banner?.fields?.subHeadline || 'Don’t get caught without the right (sound) tool. There are plenty of things that can go wrong in a complex set up and your time is important.'
            }`}</div>
          <div className='flex items-center justify-center mt-4 lg:mt-7 flex-col xxs:flex-row gap-3 lg:pl-11 pr-9'>
            <a href={`${banner?.fields?.ctaLink || '/products'}`}>
            <div className='hover:bg-black/[0.2] transition-all flex items-center bg-aquamarine justify-center space-x-2 text-black h-fit leading-[40px] rounded-full w-fit px-6 py-1 backdrop-blur-2xl'>
              <div className='whitespace-nowrap'>
                { banner?.fields?.ctaText || 'Learn more'}
              </div>
              <div className='block w-18px min-w-[18px] min-h-[18px] md:min-w-[21px] md:min-h-[21px]'><img src={ChatIcon} alt='chat-logo' /></div>
            </div>
            </a>
          </div>
        </div>
      </div>
      <div className='relative text-black lg:flex-1'>
        {/* @ts-ignore */}
        <FAQ faqProps={faq} isCompact={true} isShortForm={true} />
      </div>
    </div>
  )
};

export default memo(IntroductionHomePage);