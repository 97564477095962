import { memo, useEffect, useMemo, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import PlusIcon from '../../../assets/PlusIcon';
import MinusIcon from '../../../assets/MinusIcon';

const FAQItem: React.FC<{ question: string, answer: any, isPopUpAnswer: boolean, keyIndex: number, setCurrentQuestion: any, isCompact?: boolean }> = memo(({ question, answer, isPopUpAnswer, keyIndex, setCurrentQuestion, isCompact }) => {
    
    const toggleQuestion = () => {
        setCurrentQuestion((prev: any) => prev === keyIndex ? -1 : keyIndex);
    };

    

    const option = useMemo(() => {
        return {
            renderNode: {
                [BLOCKS.UL_LIST]: (node: any, children: any) => <ul className='mt-[14px]'>{children}</ul>,
                [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='text-16 font-inter'>{children}</li>,
                [BLOCKS.HEADING_3]: (node: any, children: any) => <h3 className='font-inter text-[20px]'>{children}</h3>,
                [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                    return <a className='hover:text-red-800' target='_blank' rel='noreferrer' href={`${node?.data?.target?.fields?.file?.url}`}>{node?.data?.target?.fields?.description ? <img className='mt-4' alt='pdf' src={node?.data?.target?.fields?.description} /> : children}</a>
                },
                [INLINES.HYPERLINK]: (node: any, children: any) => <a target='_blank' rel='noreferrer' className='hover:text-red-800 underline font-bold' href={`${node?.data?.uri}`}>{children}</a>,
            }
        }
    }, []);

    return (
        <li onClick={toggleQuestion} className={`w-full bg-[#F4F4F8] relative min-h-20 cursor-pointer  px-6 pt-5 flex flex-col pb-5 question-box rounded-2xl ${isPopUpAnswer ? 'active' : ''}`}>
            <div className='flex flex-row justify-between gap-4 items-center'>
                <div>{question}</div>
                <div className='bg-black my-auto p-2 min-h-[40px] rounded-full flex justify-center'>
                    { isPopUpAnswer ? <MinusIcon /> : <PlusIcon /> }
                </div>
            </div>
                {isCompact ? (<div className={`answer-box absolute bg-[#F9F9F9] border border-solid shadow-2xl border-green-400 top-20 p-2 right-0 rounded-xl w-11/12 ${
                    isPopUpAnswer ? 'z-10' : '-z-10'
                }`}>
                {answer && documentToReactComponents(answer, option)}
                <div className='absolute -top-[9px] right-[36px] max-w-[8px] block fill-[#F9F9F9] stroke-green-400'>
                <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" viewBox="0 0 28 31" className='overflow-visible block'>
                <g id="Group_637" data-name="Group 637" transform="translate(-1162 -417)">
                    <g id="Polygon_1" data-name="Polygon 1" transform="translate(1162 417)">
                    <path d="M 27.20340347290039 28.5 L 0.7965965270996094 28.5 L 14 1.150092840194702 L 27.20340347290039 28.5 Z" strokeWidth={6}/>
                    <path d="M 14 2.300174713134766 L 1.59318733215332 28 L 26.40681266784668 28 L 14 2.300174713134766 M 14 0 L 28 29 L 0 29 L 14 0 Z" strokeWidth={4}/>
                    </g>
                    <g id="Polygon_2" data-name="Polygon 2" transform="translate(1162 419)" stroke="#F9F9F9">
                    <path d="M 27.20340347290039 28.5 L 0.7965965270996094 28.5 L 14 1.150092840194702 L 27.20340347290039 28.5 Z"/>
                    <path d="M 14 2.300174713134766 L 1.59318733215332 28 L 26.40681266784668 28 L 14 2.300174713134766 M 14 0 L 28 29 L 0 29 L 14 0 Z" />
                    </g>
                </g>
                </svg>
                </div>
            </div>): (
                <div className='answer-box w-11/12'>
                    {answer && documentToReactComponents(answer, option)}
                </div>
            )}
            
        </li>
    );
});


const FAQ: React.FC<any> = ({faqProps, isCompact, isShortForm}) => {
    const [currentQuestion, setCurrentQuestion] = useState<number>(-1);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(window?.innerWidth <= 768);
        const handleResize = () => {
            setIsMobile(window?.innerWidth <= 768);
        };
        window?.addEventListener('resize', handleResize);
        return () => window?.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`relative ${isCompact || isMobile ? '' : 'w-2/3'} mx-auto`}>
            <ul className='faq-tab flex flex-col gap-5 w-full'>
                {(isShortForm ? faqProps?.slice(0,4) : faqProps)?.map((el: any, index: number) => <FAQItem setCurrentQuestion={setCurrentQuestion} key={`faq_${index}`} keyIndex={index} isPopUpAnswer={currentQuestion === index} question={el?.content?.[0]?.content?.[0]?.value} answer={{ ...el, content: [...el?.content?.slice(1)]}} isCompact={isCompact} />)}
            </ul>
        </div>
    );
};

export default memo(FAQ);