import { memo, useMemo } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { separateRichTextNode } from '../../../helpers/common';
import { useInfoTabContext } from '../../../context/infotab-context';
import './key-features-tab.css';


const KeyFeatures: React.FC<any> = ({ sections }) => {
  const { product } = useInfoTabContext();

    const keyFeatures: any = useMemo(() => {
        const seperatedKeyFeatures =  separateRichTextNode(product?.[0]?.fields?.keyFeatures?.content || []);
        return seperatedKeyFeatures.map(element => ({
            nodeType: BLOCKS.DOCUMENT,
            content: element,
            data: {}
        }));
      }, [product]);

      const option = useMemo(() => {
        return {
          renderNode: {
              [BLOCKS.UL_LIST]: (node: any, children: any) => <ul className='mt-[14px]'>{children}</ul>,
              [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='text-16 font-inter'>{children}</li>,
              [BLOCKS.HEADING_3]: (node: any, children: any) => <h3 className='font-inter text-[20px]'>{children}</h3>,
              [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => <a className='hover:text-red-800' target='_blank' rel='noreferrer' href={`${node?.data?.target?.fields?.file?.url}`}>{children}</a>,
              [INLINES.HYPERLINK]: (node: any, children: any) => <a target='_blank' rel='noreferrer' className='hover:text-red-800' href={`${node?.data?.uri}`}>{children}</a>,
          }
      }
    }, []);

    return (
      <div className='relative'>
          {/* Content for Specs & Details tab */}
          <div className='grid grid-cols-1 mt-6 md:grid-cols-2 gap-8 mb-10 md:mb-0 md:mt-14 w-full md:w-4/5 mx-auto'>
  
              {
                  keyFeatures?.map((element: any, index: number) => {
                      return (
                      <div key={`specs_${index}`}>
                          {element && (documentToReactComponents(element, option))}
                      </div>
                      );
                  })
              }
          </div>
      </div>
  );
};

export default memo(KeyFeatures);