import React, { memo } from 'react';
import { kebabCase } from 'lodash';
import { IContentFulEntry } from '../model/contentful-model';
import { Link } from 'react-router-dom';

interface IProps {
  entry: IContentFulEntry
  smallCard?: boolean
  isShowPrice?: boolean
  isShowDetails?: boolean
  isHorizontal?: boolean
  discount?: number
}

const CompactProductCard: React.FC<IProps> = ({ entry, smallCard, isShowPrice, isShowDetails, isHorizontal, discount }) => {
  return (
    <div className={`h-full compact-product-card-wrapper w-full ${isHorizontal && '!flex-row gap-8'}`}>
      {/* @ts-ignore */}
      <Link to={`/products/${kebabCase(JSON.stringify(entry?.fields?.name))}`}><img className={`${isHorizontal ? 'w-full' : 'w-full'} hover:scale-105 cursor-pointer transition-all`} src={entry?.fields?.thumbnailImage?.fields?.file?.url} alt={entry?.fields?.name} /></Link>
      <div className={`text-center content-wrapper lg:mt-0 ${isHorizontal ? 'w-2/3' : 'w-full'}`}>
        <div>
        <div className={`font-inter text-18 leading-7 px-16px cursor-pointer hover:scale-105`}>
          <Link to={`/products/${kebabCase(JSON.stringify(entry?.fields?.name))}`} className={`w-full ${smallCard ? 'px-2' : ''}`}>{entry?.fields?.name}</Link>
        </div>
        {
          !smallCard && (
            <p className='w-full text-wrap mt-4 text-16 text-center'>
              {entry?.fields?.description?.toString()}
            </p>
          )
        }
        </div>
        <div className='flex flex-col'>
          {isShowPrice && <div className='flex justify-center items-center mt-1 lg:mt-4'>
            <div className='text-2xl font-inter font-medium'>
              {entry?.fields?.price ? `$${Math.trunc((entry?.fields?.price as number) * 1 * ((100 - (discount || 0)) / 100))}.`  : 'Price varies by length'}
            </div>
            {Boolean(entry?.fields?.price) && <span className='pb-2 font-inter font-medium'>00</span>}

            {discount && <>
              <div className='text-16 ml-4 text-[#7B7B7B] font-inter font-medium line-through'>
                {`$${entry?.fields?.price}.`}
              </div>
              {Boolean(entry?.fields?.price) && <span className='font-inter text-[#7B7B7B] text-12 pb-1 font-medium line-through'>00</span>}
            </>}

          </div>}
          <div className='flex flex-col items-center xxs:flex-row justify-center gap-5 mt-5 mb-1'>
            <Link to={`/products/${kebabCase(JSON.stringify(entry?.fields?.name))}`}>
                {(isShowPrice || isShowDetails) && <div className='border-black hover:bg-black/[0.2] hover:cursor-pointer transition-all flex items-center justify-center space-x-5 border rounded-[20px] text-black leading-[40px] px-5 w-fit'>
                <div>
                <div className='font-inter cursor-pointer text-[14px] w-fit'>Details</div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
              </div>}
            </Link>
            {/* @ts-ignore */}
            <a target='_blank' href={entry?.fields?.buyUrl || 'https://www.amazon.com/stores/Sound+Tools/page/CA903747-A545-4448-8D59-488335F86A41?ref_=ast_bln'} rel="noreferrer">
              <div className='bg-aquamarine text-black hover:bg-black/[0.7] transition-all h-[40px] rounded-[20px] space-x-3 leading-[40px] w-[130px] flex justify-center items-center'>
                <div>
                  <div className='w-full'>Buy Now</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        
      </div>
    </div>
  )
};

export default memo(CompactProductCard);
