import React, { memo } from 'react';

const PlusIcon: React.FC = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-white block stroke-white'>
  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
    <path id="Path_207" data-name="Path 207" d="M18,7.5v21" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    <path id="Path_208" data-name="Path 208" d="M7.5,18h21" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  </g>
</svg>
);

export default memo(PlusIcon);
