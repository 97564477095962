import React, { memo, useMemo } from 'react';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import LightCarousel from '../components/light-carousel';

const HeaderTopBarPromotion: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const promotions = useMemo(() => {
    return filterItems(rawContentFul, 'topbarPromotion');
  }, [rawContentFul]);

  return (
    <div className='bg-black w-full h-10 px-5 py-2 text-white z-10'>
      <div className='flex justify-center w-full'>
      <LightCarousel>
        {
          promotions.map(promotion => (
            <div key={promotion.sys.id} className='flex items-center justify-center text-white text-16px gap-x-3'>
              <div>{promotion.fields.text}</div>
              {/* @ts-ignore */}
              <div className='cursor-pointer underline'><a href={promotion?.fields?.ctaLink || '#'}>{promotion.fields.ctaText}</a></div>
            </div>
          ))
        }
      </LightCarousel>
      </div>
    </div>
  )
};

export default memo(HeaderTopBarPromotion);
