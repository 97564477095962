import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import { Link, useSearchParams } from 'react-router-dom';

interface IProps {
  hasCategoryDescription?: boolean;
  handleProductCategory?: any;
  hasAllProductInList?: boolean;
  isCircles?: boolean;
}

const ProductCategories: React.FC<IProps> = ({ hasCategoryDescription, hasAllProductInList, isCircles }) => {
  const [searchParams] = useSearchParams();
  const { rawContentFul } = useAppContext();
  const categories = useMemo(() => {
    return _sortBy(filterItems(rawContentFul, 'productCategory'), 'fields.order');
  }, [rawContentFul]);
  const [highlightedCategory, setHighlightedCategory] = useState<number>(() => {
    const index = categories?.findIndex(category => category?.fields?.name?.toString()?.toLowerCase() === searchParams.get('category'));

    return index < 0 ? 0 : index;
  });

  const handleCategoryClick = useCallback((category) => {
    setHighlightedCategory(category?.fields?.order);
  }, []);

  useEffect(() => {
    const categoryIndex = searchParams.get('category') ? categories?.findIndex(category => category?.fields?.name?.toString()?.toLowerCase() === searchParams.get('category')) : 0;
    setHighlightedCategory(categoryIndex);
  }, [categories, searchParams])

  return (
    <div className='px-4 py-5'>
      <div className='product-category-wrapper !p-0 w-full whitespace-nowrap md:whitespace-normal overflow-x-auto'>
        <ul className='list-none p-0 md:space-x-8 space-x-3  text-center'>
        {
          categories?.slice(hasAllProductInList ? 0 : 1).map(item => (
            <li className='inline-block w-28 md:w-[100px] transition-all' key={item.sys.id} onClick={() => handleCategoryClick(item)}>
              <Link to={`/products?category=${encodeURIComponent(item?.fields?.name?.toString().toLowerCase() || '')}`}>
                <div className='flex flex-col w-full items-center'>
                  <div className={`w-full h-auto md:w-[96px] md:h-[96px] rounded-full md:pt-0.5 border-2 border-solid transition-all duration-500 ${item?.fields?.order === highlightedCategory ? 'border-black' : 'border-white'}`}>
                  <div style={{ backgroundColor: `${item.fields.colorHex || '#fff'}`}} className={`md:w-[88px] md:h-[88px] w-[108px] h-[108px] aspect-square ${isCircles ? 'rounded-full' : 'rounded-md'} lg:rounded-full m-auto`}>
                    {/* @ts-ignore */}
                    <img className='mx-auto w-28 h-28 md:h-full md:w-4/5 object-contain p-2' src={item.fields.photo?.fields?.file?.url} alt={item?.fields?.name?.toString() || ''} />
                  </div>
                  </div>
                  
                  <p className={`capitalize w-full text-wrap mt-4 text-[14px] text-center font-sans ${item?.fields?.order === highlightedCategory && 'font-bold'}`}>
                    {item.fields.name?.toString().toLowerCase()}
                  </p>
                </div>
              </Link>
            </li>
          ))
        }
        </ul>
      </div>
    </div>
  )
};

export default memo(ProductCategories);
