import { isValidElement, memo, useMemo } from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { separateRichTextNode } from '../../../helpers/common';
import { useInfoTabContext } from '../../../context/infotab-context';

const DownloadsTab: React.FC = () => {
    const { product } = useInfoTabContext();

    const downloads: any = useMemo(() => {
        const seperatedDownloads =  separateRichTextNode(product?.[0]?.fields?.faq?.content || []);
        return seperatedDownloads.map(element => ({
            nodeType: BLOCKS.DOCUMENT,
            content: element,
            data: {}
        }));
      }, [product]);

      const option = useMemo(() => {
        return {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
                  return <img src={`${node?.data?.target?.fields?.file?.url}`} alt={`${node?.data?.target?.fields?.title}`} className='rounded-2xl w-full md:max-w-[500px] mx-auto' />
                },
                [BLOCKS.HEADING_3]: (node: any, children: any) => <div className='font-inter text-20 mb-5 pl-2 lg:pl-16 capitalize'>{children}</div>,
                [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
                  const videoSrc = children?.[0];
                  if (isValidElement(videoSrc)) {
                    return <div>{children}</div>;
                  }

                  return children?.[0]?.includes('youtu') ? 
                    <div className='youtube-embeded'><iframe className='w-full h-full min-h-[400px] rounded-2xl' src={videoSrc} title='YouTube video player' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerPolicy='strict-origin-when-cross-origin' allowFullScreen></iframe></div>
                    : <div>{children}</div>;
                },
                [BLOCKS.UL_LIST]: (node: any, children: any) =>  <ul className='flex flex-col gap-4 list-disc pl-5 py-5 contentful-list'>{children}</ul>,
                [BLOCKS.HR]: (node: any, children: any) => <div className='mt-4'></div>,
                [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='px-3'>{children}</li>,
                [BLOCKS.TABLE]: (node: any, children: any) => <div className='border border-solid rounded-2xl my-4'><table className='table-fixed text-center rounded-2xl p-6 border border-solid overflow-hidden w-full font-sans'>{children}</table></div>,
                [BLOCKS.TABLE_HEADER_CELL]: (node: any, children: any) => <th className=' font-semibold text-16 p-2 border bg-gray-300'>{children}</th>,
                [BLOCKS.TABLE_ROW]: (node: any, children: any) => <tr className='border'>{children}</tr>,
                [BLOCKS.TABLE_CELL]: (node: any, children: any) => <td className='border'>{children}</td>,
                [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => <a className='hover:text-red-800 underline font-bold' target='_blank' rel='noreferrer' href={`${node?.data?.target?.fields?.file?.url}`}>{children}</a>,
                [INLINES.HYPERLINK]: (node: any, children: any) => <a target='_blank' rel='noreferrer' className='hover:text-red-800 underline font-bold' href={`${node?.data?.uri}`}>{children}</a>,
            }
        }
    }, []);

    return (
        <div className='m-auto flex flex-col gap-5 mb-10 md:mb-0 md:mt-14 w-full md:w-4/5 mx-auto'>
            {/* Content for Function tab */}
            {downloads?.[0] && documentToReactComponents(downloads?.[0], option)}
        </div>
    );
};

export default memo(DownloadsTab);