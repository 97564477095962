import React, { memo, useEffect, useMemo, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import Breadcrumb from '../components/breadcrumb';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import CompactProductCard from '../components/compact-product-card';
import ProductCategories from '../components/product-categories';
import './products-page.css';
import { useSearchParams } from 'react-router-dom';

const ProductsPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { rawContentFul } = useAppContext();
  const banner = useMemo(() => filterItems(rawContentFul, 'banner')?.filter(data => (
    data?.fields?.location === 'products'
  ))?.[0], [rawContentFul]);
  const categories = useMemo(() => {
    return _sortBy(filterItems(rawContentFul, 'productCategory'), 'fields.order')?.map(el => el?.fields?.name);
  }, [rawContentFul]);
  const productsFiltered = useMemo(() => {
    const pf =  _sortBy(filterItems(rawContentFul, 'products'), 'fields.name');
    const temp = categories?.map(el => ({
      title: el,
      //@ts-ignore
      groupProducts: pf?.filter(product => product?.fields?.category?.fields?.name?.toString()?.toLowerCase() === el?.toLowerCase())
    }));


    return temp;
  }, [rawContentFul, categories]);
  const [products, setProducts] = useState(productsFiltered);
  const queryParam = useMemo(() => searchParams.get('category'), [searchParams]);

  useEffect(() => {
    //@ts-ignore
    setProducts(productsFiltered.filter((product => {
      const categoryParam = queryParam;
      
      //@ts-ignore
      if (categoryParam === 'all products' || !categoryParam) return product?.title?.toLowerCase() !== 'all products';
      //@ts-ignore
      return product?.title?.toLowerCase() === categoryParam;
    })));
  }, [productsFiltered, queryParam]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  // []

  return (
    <div className='w-full h-full'>
      <div className='flex flex-col'>
      <div className='product-banner h-56 relative rounded-2xl mx-4 lg:mx-0 my-5 lg:mt-0 bg-cover bg-right md:bg-center' 
      //@ts-ignore
        style={{ backgroundImage: `url('${banner?.fields?.photo?.fields?.file?.url || ''}')`}}
      >
        <div className='hidden lg:flex absolute top-0 bottom-0 h-fit my-auto left-14 text-black flex-col gap-3'>
          <div className='font-inter text-36 font-bold'>{banner?.fields?.headline || 'The Most Advanced System Ever'}</div>
          <div className='font-sans text-2xl font-normal'>{ banner?.fields?.subHeadline || 'Line array laser system with built-in networking and portable reader'}</div>
        </div>
      </div>
        <div className='px-4 w-full text-left'>
          <Breadcrumb data={[
            {
              text: 'Products',
              link: '/products'
            }
          ]} />
        </div>
        <ProductCategories hasCategoryDescription={true} hasAllProductInList={true} isCircles={true}/>
      </div>
      <div className='px-4 pb-6 lg:px-16 xl:max-w-container xl:px-0 mx-auto w-full border-b'>
        {products?.map(p => (
          <div className='pb-6'>
            <div className='w-full font-inter text-22 leading-9 lg:text-4xl'>{p.title}</div>
            <div className='products'>
              <div className={`flex flex-col md:grid md:grid-cols-2 ${p.title === 'TESTERS' ? 'lg:grid-cols-2' : 'lg:grid-cols-3'} gap-5 mt-7 md:mt-8`}>
                {p.groupProducts.map(product => (
                  //@ts-ignore
                  <div className='flex relative product-item bg-white p-4 rounded-2xl border border-[#BABACE]' key={product?.sys?.id}>
                    {product?.fields?.discount && <div className='absolute !bg-black px-5 py-2.5 font-sans rounded-lg whitespace-nowrap !text-white h-fit w-fit text-16 font-medium'>SALE {product?.fields?.discount}%</div>}
                    <CompactProductCard entry={product} isShowPrice={true} isShowDetails={true} discount={product?.fields?.discount as number} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    
  )
};

export default memo(ProductsPage);
