import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

const HeaderMeta: React.FC = () => (
  <Helmet>
    <title>Align Array</title>
    <meta name='description' content='Line array laser system with built-in networking and portable reader' />
    <link rel='canonical' href={window.location.href} />
  </Helmet>
);

export default memo(HeaderMeta);
