import React, { memo, useMemo } from 'react';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import CompactProductCard from '../compact-product-card';

const HomePageFeaturedProduct: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const featuredProducts = useMemo(() => {
    return filterItems(rawContentFul, 'products').filter(item => item.fields?.isFeatured === true).slice(0, 4);
  }, [rawContentFul]);
  const sectionInfo = useMemo(() => filterItems(rawContentFul, 'sectionInfo')?.filter(data => (
    data?.fields?.type === 'featuredProductSection'
  ))?.[0], [rawContentFul]);

  return (
    <div className='flex flex-col mx-4 lg:mx-0 lg:mt-12 feature-producs'>
      <div className='w-full font-inter text-22 leading-9 lg:text-4xl'>{sectionInfo?.fields?.sectionTitle || 'Featured Products'}</div>
      <div className='feature-product-container flex flex-row gap-5 mt-5 lg:mt-7 relative'>
        <div className='flex flex-row gap-5 overflow-x-auto custom-scrollbar pb-7 lg:pb-11 xs:pb-0 z-[1]'>
          {featuredProducts.map((product, index) => (
                <div key={`product_${index}`} className='flex product-item relative w-full xs:min-w-min min-w-[320px] rounded-2xl border border-[#BABACE] bg-white p-4 lg:px-2'>
                    {product?.fields?.discount &&  <div className='absolute !bg-black px-5 py-2.5 font-sans rounded-lg whitespace-nowrap !text-white h-fit w-fit text-16 font-medium'>SALE {product?.fields?.discount}%</div>}

                <CompactProductCard entry={product} isShowPrice={true} isShowDetails={true} discount={product?.fields?.discount as number} />
              </div>
            ))}
        </div>
        <div className='w-full border-b border-solid absolute bottom-0.5 xs:hidden'></div>
      </div>
    </div>
  )
};

export default memo(HomePageFeaturedProduct);
