import React, { memo, useMemo } from 'react';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';

const HomePageHero: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const [heroPhoto, heroHompageContent] = useMemo(() => {
    const items = filterItems(rawContentFul, 'homePageHero');

    return [items[0], items];
  }, [rawContentFul]);

  return (
    // @ts-ignore
    <div style={{ backgroundImage: `url(${heroPhoto?.fields?.photo?.fields?.file.url})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
      className='w-full h-64 lg:h-[588px] p-5 lg:w-full m-auto lg:rounded-2xl lg:max-w-container flex relative justify-center items-center'>
        <div className='max-w-md-container pl-0 sm:pl-20 xl:max-w-container mx-auto w-full'>
        <div className='text-white font-inter font-bold text-28 pr-4 xs:pr-20 leading-8 sm:mt-5 lg:my-auto lg:uppercase lg:text-[53px] lg:max-w-2xl lg:leading-[50px]'>
        {heroPhoto?.fields?.headline}
      </div>
      <div className='text-white font-sans text-16 w-4/5 xs:w-2/3 mt-1.5 lg:mt-4 lg:text-20'>
        {heroPhoto?.fields?.subHeadline}
      </div>
      <Link to={(heroHompageContent?.[0]?.fields?.ctaLink as string) || '/products'}>
      <div className='font-poppins whitespace-nowrap hover:bg-white/[0.3] text-20 transition-all flex items-center justify-center rounded-[60px] text-black 
       mt-5 xs:mt-5 lg:mt-7 py-4 px-8 w-fit bg-aquamarine'>
          {heroPhoto?.fields?.ctaText}
      </div>
      </Link>
        </div>
      <div className='absolute max-w-[360px] max-h-36 rounded-2xl right-10 top-20 bg-white hidden xl:block'>
          {/* @ts-ignore */}
          <Link to={`/products/${kebabCase(JSON.stringify(heroHompageContent?.[0]?.fields?.highlightProduct?.fields?.name || ''))}`}>
            <div className='flex flex-row max-h-36'>

          <div className='w-full max-h-36 m-auto object-contain px-4 box-border'>
            
            {/* @ts-ignore */}
            <img className='block object-contain' src={heroHompageContent?.[0]?.fields?.highlightProduct?.fields?.thumbnailImage?.fields?.file?.url} alt='product' />
          </div>
          <div className='flex flex-col p-2 pl-0'>
            {/* @ts-ignore */}
            <div className=' text-18 font-inter'>{heroHompageContent?.[0]?.fields?.highlightProduct?.fields?.name || ''}</div>
            {/* @ts-ignore */}
            <div className='font-sans text-14 text-clip'>{heroHompageContent?.[0]?.fields?.highlightProduct?.fields?.descriptionOnHighlightWindow || ''}</div>
          </div>
        </div>
          </Link>
        <div className='absolute -left-7 top-16'>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="87" viewBox="0 0 33 87">
  <g id="Group_637" data-name="Group 637" transform="translate(-1009 -251)">
    <circle id="Ellipse_47" data-name="Ellipse 47" cx="10" cy="10" r="10" transform="translate(1009 318)" fill="#fff"/>
    <path id="Path_225" data-name="Path 225" d="M2090,7131h-22.593v68.029" transform="translate(-1049 -6879)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 6"/>
    <circle id="Ellipse_48" data-name="Ellipse 48" cx="7" cy="7" r="7" transform="translate(1012 321)" fill="#66db9f"/>
  </g>
</svg>

        </div>
      </div>
    </div>
  )
};

export default memo(HomePageHero);