import React, { memo, useCallback } from 'react';
import _map from 'lodash/map';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import { testRegex } from '../helpers/article-data';

interface IDataLink {
  text?: string;
  link?: string;
}

interface IProps {
  data: IDataLink[];
}

const Breadcrumb: React.FC<IProps> = ({ data }) => {
  const getDisplayText = useCallback((itemText: string = ''): string => {
    const match: RegExpExecArray | null = (new RegExp(testRegex)).exec(itemText);

    return _get(match, 1, itemText);
  }, []);

  return (
    <span className='space-x-1 text-[#7B7B7B] text-14'>
      <Link to='/'>Home</Link>
      {
        _map(data, (item: IDataLink, index: number) => (
          <React.Fragment key={index}>
              {(item?.link || '')?.slice(1)?.split('/').map((link, index, arr) => (
                <span key={index} className={`${index === arr.length - 1 ? '!text-black pointer-events-none' : ''} ${typeof item.link === 'undefined' ? 'pointer-events-none' : ''}`}>
                  <Link to={`/${link || ''}`}>
                    {`/ ${getDisplayText(item.text).split('/')?.[index]}`}
                  </Link>
                </span>
              ))}
          </React.Fragment>
        ))
      }
    </span>
  )
};

export default memo(Breadcrumb);
