import React, { memo, useMemo } from 'react';
import _sortBy from 'lodash/sortBy';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import './testimonial.css';

const HomePageTestimonial: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const testimonials = useMemo(() => {
    return _sortBy(filterItems(rawContentFul, 'testimonial'), 'fields.order');
  }, [rawContentFul]);
  const sectionInfo = useMemo(() => filterItems(rawContentFul, 'sectionInfo')?.filter(data => (
    data?.fields?.type === 'testimonialSection'
  ))?.[0], [rawContentFul]);


  return (
    <div className='py-7 lg:py-9 rounded-2xl lg:border mt-0 lg:mt-11 mb-12'>
      <div className='w-full flex flex-col gap-8'>
        <div className='w-full font-inter text-[22px] sm:text-4xl text-center'>{sectionInfo?.fields?.sectionTitle || 'Why chose us'}</div>
          <div className='grid grid-cols-2 lg:grid-cols-4 w-full gap-x-2.5 gap-y-8'>
            {
            testimonials?.map((item: any, index: number) => (
              <div className='w-full px-4' key={`${item?.id}_${index}`}>
                {/* <div>{index-1}</div> */}
                <div className='flex flex-col w-full h-full'>
                <div className='flex flex-col w-full items-center justify-start h-full'>
                  <div className='w-[90px] h-[90px] rounded-full bg-black relative'>
                    {/* @ts-ignore */}
                    <div className=''>
                    <img className='mx-auto object-cover' src={item.fields.photo?.fields?.file?.url} alt={item?.fields?.name?.toString() || ''} />
                    </div>
                  </div>
                  <div>
                    <div className='w-fit text-center'>
                      <p className='text-18 font-inter mt-[18px]'>
                        {item?.fields?.author?.toString()}
                      </p>
                      <p className='text-[15px] font-sans text-[#4A4A4A] mt-3'>
                        {item?.fields?.company?.toString()}
                      </p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            ))
          }
          </div>
      </div>
    </div>
  )
};

export default memo(HomePageTestimonial);
